import React from 'react';
import {Layout} from '../modules/layout/containers/Layout';
import {graphql} from 'gatsby';
import SEO from '../modules/layout/containers/SEO';
import {mapPage} from '../modules/core/mapper/PageMapper';
import {SimplePage} from '../modules/uxui/SimplePage';
import _get from 'lodash/get';
import {useSiteMetadata} from '../modules/common/hooks/useSiteMetadata';
import {computeSEOMeta} from '../modules/core/util/SEOUtil';

interface Props {
    location: any;
    data: {
        wpgraphql: {
            page: any;
        };
    };
}
export const PageTpl: React.FC<Props> = ({data, location}) => {
    const rawPage = _get(data, 'wpgraphql.page');
    const page = mapPage(rawPage);
    const siteMetadata = useSiteMetadata();
    const {title, metadata} = computeSEOMeta(siteMetadata, rawPage.seo);
    return (
        <Layout location={location}>
            <SEO title={title} meta={metadata} />
            <SimplePage title={page.title} htmlContent={page.content} />
        </Layout>
    );
};

export const query = graphql`
    query($id: ID!) {
        wpgraphql {
            page(id: $id) {
                id
                pageId
                date
                dateGmt
                link
                menuOrder
                slug
                title
                status
                guid
                modified
                uri
                content
                seo {
                    ...WPSEOFragment
                }
            }
        }
    }
`;

export default PageTpl;
