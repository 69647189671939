import {Page} from '../models/Page';

export function mapPage(node: any): Page {
    return {
        id: node.id,
        content: node.content,
        date: node.date,
        guid: node.guid,
        link: node.link,
        menuOrder: node.menu_order,
        modified: node.modified,
        path: node.path,
        slug: node.slug,
        status: node.status,
        template: node.template,
        title: node.title,
        type: node.type,
        pageId: node.pageId,
        seo: node.seo,
    };
}
