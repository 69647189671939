import React from 'react';
import classNames from 'classnames';
import {decodeHtmlCharCodes} from '../../utils';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            paddingBottom: theme.spacing(2),
        },
        pageContent: {
            '& p': {
                ...theme.typography.body1,
                textAlign: 'justify',
            },
            '& h2': {
                ...theme.typography.h2,
            },
            '& h3': {
                ...theme.typography.h3,
            },
            '& h4': {
                ...theme.typography.h4,
            },
            '& figure': {
                margin: 0,
            },
            '& figure img': {
                width: '100%',
            },
            '& .wp-block-image': {
                textAlign: 'center',
            },
        },
    }),
);

interface Props {
    title: string;
    htmlContent: any;
}

export const SimplePage: React.FC<Props> = ({title, htmlContent}) => {
    const classes = useStyles();
    return (
        <Container maxWidth="md">
            <Typography variant="h1" className={classes.title}>
                {decodeHtmlCharCodes(title)}
            </Typography>
            <Box
                className={classNames('page-content', classes.pageContent)}
                dangerouslySetInnerHTML={{
                    __html: decodeHtmlCharCodes(htmlContent),
                }}
            />
        </Container>
    );
};
